import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import { UserSession } from '@custom-types/common'

type UserSessionType = {
  data?: UserSession,
  mutate(data?: UserSession, shouldRevalidate?: boolean): Promise<any> 
}

export default function useUser({
  redirectIfFound = false,
}  = {}) {
  const { data: user, mutate: mutateUser }: UserSessionType = useSWR('/api/user')

  useEffect(() => {
    if (!redirectIfFound || !user) return;

    //NOTE: Fix redirect
    if (
      (redirectIfFound && !user?.isLoggedIn) ||
      (redirectIfFound && user?.isLoggedIn)
    ) {
      Router.push(user.isLoggedIn ? user.settings ? user.settings.livemode ? '/dashboard' : '/test/dashboard' : '/test/dashboard' : '/login');
    }
  }, [user, redirectIfFound])

  return { user, mutateUser }
}